<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="news-list-view" class="data-list-container">
    <feather-icon class="sm:inline-flex xl:hidden cursor-pointer" icon="MenuIcon" style="height: 20px; margin-right: 10px;" @click.stop="showSidebar"></feather-icon>
    <vs-table ref="table" :max-items="searchVar.itemsPerPage" :data="news">  <!-- multiple v-model="selected" pagination :max-items="itemsPerPage" search :data="news">-->

      <div slot="header" class="flex flex-wrap items-center flex-grow">
        <datepicker type="date" range v-model="dateRange2" lang="kr" style="width: 200px;" />
        <v-select style="width: 100px;" placeholder="기자" label="nickname" :reduce="nickname => nickname.idx" v-model="writer_s"
          :options="writer"/>
        <v-select style="width: 100px" placeholder="카테고리" label="name" :reduce="name => name.code" v-model="category1"
          :options="categories1"/>
        <v-select style="width: 150px" placeholder="시리즈" label="name" :reduce="name => name.code" v-model="series"
          :options="seriesArray"/>
        <v-select style="width: 100px" placeholder="검색옵션" label="name" :clearable="false" v-model="searchType"
          :options="[{name: '제목', code: 'title'},{name: '본문', code: 'description'},{name: '기사번호', code: 'newsId'}]"/>
        <div style="padding-top: 0;">
          <vs-input class="inputx" placeholder="기사 검색" v-model="searchText" @keyup.enter="searchNewsList(searchVar)"/>
        </div>
        <vs-button style="width: 50px;" color="primary" type="border" class="p-2 cursor-pointer" @click="searchNewsList(searchVar)">검색</vs-button>
        <div  id="data-list-search-rank" style="padding-top: 0;">
          <div class="p-2 border border-solid border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
            <span class="mr-2">{{ ((currentx - 1) * searchVar.itemsPerPage) + 1 }} - {{ numOfNews >= searchVar.itemsPerPage ? (currentx * searchVar.itemsPerPage) : numOfNews }} of {{ numOfNews }}</span>
          </div>
        </div>
      </div>

      <template slot="thead">
        <vs-th>기사 id</vs-th>
        <vs-th>기사 제목</vs-th>
        <vs-th style="width: 55px;">상태</vs-th>
        <vs-th style="width: 90px;">송고시간<br>(작성시간)</vs-th>
        <vs-th style="width: 70px;">작성자</vs-th>
        <vs-th style="min-width: 60px; letter-spacing: -2px;">카테고리</vs-th>
        <vs-th>View</vs-th>
        <vs-th>포털</vs-th>
      </template>

      <template slot-scope="{data}">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td><p @click="showNews(indextr, tr.id)">{{ tr.id }}</p></vs-td>
          <vs-td><div class="flex"><div style="padding:10px" @click="showNews(indextr, tr.id)"><span v-html="tr.b_sbj"/><span v-if="tr.image_count > 0"><feather-icon style="margin-left: 5px; vertical-align: middle;" size="17" icon="ImageIcon"></feather-icon>{{tr.image_count}}</span></div></div></vs-td>
          <vs-td><span v-html="stateColor(tr.state, stateToText(tr.state))"/></vs-td>
          <vs-td v-tooltip="{content: '송고시간: ' +tr.d_pass2 + '<br>수정시간: ' + tr.d_modi + '<br>작성시간: ' + tr.d_write, placement: 'top'}">{{ tr.d_pass }}</vs-td>
          <vs-td>{{ tr.writer }}</vs-td>
          <vs-td>{{ tr.menu_code }}</vs-td>
          <vs-td>{{ tr.viewed }}</vs-td>
          <vs-td v-tooltip="{content: tr.out_site, placement: 'top'}"><span v-html="portalText(tr.out_site)"/></vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <div>
        <vs-pagination :total="numOfPages" v-model="currentx"></vs-pagination>
    </div>

    <!-- Start: 뉴스 상세보기 팝업 -->
    <div id="div-with-loading" class="news-detail-form vs-con-loading__container">
      <vs-popup ref="popup-newsDetail" classContent="popup-newsDetail" title="기사 상세" :active.sync="newsDetailModalActive">
        <div class="items-center">
          <div class="vx-row mb-6" style="margin:0 auto; position: sticky; top: 0;">
            <vs-button vs-type="gradient" color="success" icon="create" @click="writeNews(selectedId)">기사 작성</vs-button>
          </div>
          <div class="news-detail" id="view_con" style="margin: 20px 0px 0px;" v-html="newsDetail.data"></div>
        </div>
      </vs-popup>
    </div>
    <!-- End: 뉴스 상세보기 팝업 -->
  </div>
</template>

<script>
import vSelect from 'vue-select'
//you need to import the CSS manually (in case you want to override it)
import moment from 'moment';

export default {
  components: {
    'v-select': vSelect,
  },
  data() {
    return {
      selectedIndex: 0,
      selectedId: 0,
      selectedNewsData: [],
      news: [{descr: ''}],
      isHuboNews: [],
      isHuboNewsSnapshot: [],
      currentx: 1,
      numOfNews: 0,
      numOfPages: 0,
      searchVar: {
        startRow: 0,
        itemsPerPage: 15,
        state: null,
        category1: null,
        series: null,
        rank: null,
        writer: null,
        searchType: 'title',
        searchText: '',
        startDate: moment().subtract(1, 'month').format('YYYY-MM-DD'),
        endDate: moment().add(2, 'week').format('YYYY-MM-DD'),
        department: '',
      },
      state: null,
      category1: null,
      series: null,
      rank: null,
      writer_s: null,
      searchType: {name: '제목', code: 'title'},
      searchText: '',
      dateRange2: [new Date(moment().subtract(1, 'month')), new Date(moment().add(2, 'week'))],
      newsDetail: '',
      writer: [],
      types: [],
      states: [],
      categories1: [],
      categories1_ok: false,
      categories2: [],
      categories2_view: [],
      seriesArray: [],
      category2Disable: true,
      portals: [],
      portalSelect: [],
      isMounted: false,
      addNewDataSidebar: false,
      newsDetailModalActive: false,
    }
  },
  beforeRouteLeave (to, from, next) {
    this.newsDetailModalActive = false;
    setTimeout(() => { next(); }, 100);
  },
  watch: {
    '$route.query': function() {
      console.log(this.$route.query);
      this.newsDetailModalActive = false;
      if(this.$route.query.startRow) this.searchVar.startRow = parseInt(this.$route.query.startRow);
      else {
        this.searchVar.startRow = 0;
        this.currentx = 1;
        if(this.$route.query.startRow != 0){
          this.state= null;
          this.category1= null;
          this.series= null;
          this.rank= null;
          this.writer_s= null;
          this.searchText= '';
        }
      }
      if(this.$route.query.state) this.searchVar.state = this.$route.query.state;
      else this.searchVar.state = null;
      if(this.$route.query.category1) this.searchVar.category1 = this.$route.query.category1;
      else this.searchVar.category1 = null;
      if(this.$route.query.series) this.searchVar.series = this.$route.query.series;
      else this.searchVar.series = null;
      if(this.$route.query.rank) this.searchVar.rank = this.$route.query.rank;
      else this.searchVar.rank = null;
      if(this.$route.query.writer) this.searchVar.writer = this.$route.query.writer;
      else this.searchVar.writer = null;
      if(this.$route.query.searchText) this.searchVar.searchText = this.$route.query.searchText;
      else this.searchVar.searchText = '';
      if(this.$route.query.startDate) this.searchVar.startDate = this.$route.query.startDate;
      else this.searchVar.startDate = moment().subtract(1, 'month').format('YYYY-MM-DD');
      if(this.$route.query.endDate) this.searchVar.endDate = this.$route.query.endDate;
      else this.searchVar.endDate = moment().add(2, 'week').format('YYYY-MM-DD');
      // if(this.$route.query.department || this.$route.query.department == '') this.searchVar.department = this.$route.query.department;
      // else this.searchVar.department = this.activeUserInfo.department;
      this.getNewsList(this.searchVar);
    },
    currentx: function() {
                                    // currentx-1 is value before changed by watch: -> currentx:
      let currentx = this.currentx;
      if(currentx <= 0){ currentx = 1 }
      this.searchVar.startRow = (currentx-1) * this.searchVar.itemsPerPage;
      window.scrollTo(0,0);
      console.log(this.searchVar)
      this.$router.push({path: '/dailianList', query: this.searchVar})
      // this.getNewsList(this.searchVar);
    }
  },
  computed: {
    currentPage() {
      if(this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    // PROFILE
    activeUserInfo() {
        return this.$store.state.AppActiveUser;
    },
  },
  methods: {
    portalText(data){
      let portalText;
      var sendFlg = 0;
      if(data){
        data.split("|").forEach(sended =>{
          if(this.$store.state.portals.find(e => e.name.toLowerCase() == sended.toLowerCase() || e.oldName == sended)){
            sendFlg++;
          }
        })
        if(sendFlg >= this.$store.state.portals.length) {
          portalText = "완";
        }else if(sendFlg > 0){
          portalText = "미완";
        }else{
          portalText = "";
        }
      }else{
        portalText = "";
      }
      return(portalText);
    },
    stateToText(state){
      let stateText;
      switch(state)
      {
        case '*': stateText ="상태"; break;
        case '0': stateText ="보류"; break;
        case '1': stateText ="송료"; break;
        case '2': stateText ="송예"; break;
        case '3': stateText ="편료"; break;
        case '4': stateText ="편중"; break;
        case '8': stateText ="작료"; break;
        case '9': stateText ="작중"; break;
      }
      return(stateText);
    },
    stateColor(state, text){
      let stateText;
      switch(state)
      {
        case '0': stateText ="<font color='black'>" + text.replace(/<\/?(span[^>]*|u[^>]*|em[^>]*|strong[^>]*|p[^>]*)>/g, '') + "</font>"; break;
        case '1': stateText ="<font color='blue'>" + text.replace(/<\/?(span[^>]*|u[^>]*|em[^>]*|strong[^>]*|p[^>]*)>/g, '') + "</font>"; break;
        case '2': stateText ="<font color='#333300'>" + text.replace(/<\/?(span[^>]*|u[^>]*|em[^>]*|strong[^>]*|p[^>]*)>/g, '') + "</font>"; break;
        case '3': stateText ="<font color='red'>" + text.replace(/<\/?(span[^>]*|u[^>]*|em[^>]*|strong[^>]*|p[^>]*)>/g, '') + "</font>"; break;
        case '4': stateText ="<font color='#666600'>" + text.replace(/<\/?(span[^>]*|u[^>]*|em[^>]*|strong[^>]*|p[^>]*)>/g, '') + "</font>"; break;
        case '8': stateText ="<font color='#666600'>" + text.replace(/<\/?(span[^>]*|u[^>]*|em[^>]*|strong[^>]*|p[^>]*)>/g, '') + "</font>"; break;
        case '9': stateText ="<font color='darkviolet'>" + text.replace(/<\/?(span[^>]*|u[^>]*|em[^>]*|strong[^>]*|p[^>]*)>/g, '') + "</font>"; break;
      }
      return(stateText);
    },
    descrToArticle (descr, imgOption){
      // console.log('imgOption: ', imgOption);
      const root="https://cdnimage.dailian.co.kr/news/";
      let textTemp = descr;

      while (textTemp.match(/<@IMG(\d{1,5})>/)){
        const tmp = textTemp.match(/<@IMG(\d{1,5})>/)
        if(imgOption[tmp[1]-1]){
          textTemp = textTemp.replace(tmp[0], "<p><img src='" + root + imgOption[tmp[1]-1].img + "'></p><p>"
                                      + imgOption[tmp[1]-1].img_c.split(/<@IMG[^>]*>/g).join('') + "</p>");
        }else { textTemp = textTemp.replace(tmp[0], '') }
      }      
      return textTemp;
    },
    makeWriteData(){
      // console.log(this.selectedNewsData)
      const selectedNewsData = this.newsDetail.raw[0][0];
      const text = this.descrToArticle(selectedNewsData.descr, this.newsDetail.raw[1]);
      console.log(text)
      return {
        mainTitle: selectedNewsData.b_sbj, subTitle: selectedNewsData.s_sbj, text, 
        category1: {code: ""}, mno1: this.activeUserInfo.id, state: {code: 9}, images: [], tag: [], rel: [],
        writer: this.activeUserInfo.id, d_pass: moment(), name: this.activeUserInfo.name, tailname: this.activeUserInfo.tail
      }
    },
    writeNews(newsId){
      const thisIns = this;
      console.log("write: " + newsId);
      this.newsDetailModalActive = false;
      this.$http.post('/api/writeArticles', this.makeWriteData(newsId)).then(function (response) {
        thisIns.$router.push({path: '/writeArticles', query: { id: response.data.id}});
      })
    },
    showNews(index, newsId){
      this.newsDetailModalActive = true;
      this.selectedIndex = index;
      this.selectedId = newsId;
      console.log(this.selectedIndex, this.selectedId);
      this.newsDetail = '';
      const thisIns = this;
      this.$vs.loading({
        scale: 1.5
      });
      this.$http.post('/api/getDailianNews/detail', {id: newsId})
        .then(function (response) {
          console.log(response.data);
          thisIns.newsDetail = response.data;
          thisIns.$vs.loading.close();
        })
        .catch(function (error) {
          var errorText = error.toString();
          if(errorText.includes('401')){
            alert('로그인 해주세요!')
            thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
          }
          thisIns.$vs.notify({
            title:'Error',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.$vs.loading.close();  
        });
    },
    changeItemPerPage(pageNum) {
      this.renderMethod.itemsPerPage = pageNum
      // this.getNewsList(this.searchVar)
    },
    searchNewsList(data){
      data.startRow = 0;
      // console.log(this.state)
      data.state = this.state;
      data.category1 = this.category1;
      data.series = this.series;
      data.rank = this.rank;
      data.writer = this.writer_s;
      data.searchText = this.searchText;
      data.searchType = this.searchType.code;
      data.startDate = moment(this.dateRange2[0]).format('YYYY-MM-DD');
      data.endDate = moment(this.dateRange2[1]).format('YYYY-MM-DD');
      data.department = '';
      this.$router.push({path: '/dailianList', query: data})
      this.currentx = 1;
      // this.getNewsList(data);
    },
    getNewsList(data) {
      console.log(data.state, data.category1, data.writer, data.series)

      data.level = this.activeUserInfo.level
      data.id = this.activeUserInfo.id
      
      this.$vs.loading({
        scale: 1.5
      });
      const thisIns = this;
      this.$http.post('/api/getDailianNews/list', data)
        .then(function (response) {
          // console.log(response);
          if(response.data[0][0].news_count){
            thisIns.numOfNews = response.data[0][0].news_count;
            thisIns.numOfPages = Math.ceil(response.data[0][0].news_count / thisIns.searchVar.itemsPerPage);
            thisIns.news = response.data[1];
            thisIns.sentNewsflashCnt = response.data[2][0].cnt;
          }else{
            thisIns.numOfNews = 0;
            thisIns.numOfPages = 0;
            thisIns.news = [{descr: ''}];
          }
          thisIns.$vs.loading.close();          
        })
        .catch(function (error) {
          var errorText = error.toString();
          if(errorText.includes('401')){
            alert('로그인 해주세요!')
            thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
          }
          thisIns.$vs.notify({
            title:'Error',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.$vs.loading.close(); 
        });
    },
    getOrderStatusColor(status) {
      if(status == 'on hold') return "warning"
      if(status == 'delivered') return "success"
      if(status == 'canceled') return "danger"
      return "primary"
    },
    getPopularityColor(num) {
      if(num > 90) return "success"
      if(num >70) return "primary"
      if(num >= 50) return "warning"
      if(num < 50) return "danger"
      return "primary"
    },
    showSidebar() {
        this.$store.commit('TOGGLE_IS_SIDEBAR_ACTIVE', true);
    },
  },
  created() {
    const thisIns = this;
    this.$http.post('/api/getDailianNews/options')
      .then(function (response) {  
        thisIns.categories1 = [{code: '', name: '전체'}].concat(response.data[0]);
        thisIns.states = thisIns.$store.state.states;
        thisIns.writer = response.data[1];
        thisIns.seriesArray = response.data[2];
        thisIns.portals = thisIns.$store.state.portals;
        // console.log(thisIns.$store.state.portals);
        const tmpPage = parseInt(thisIns.$route.query.startRow / thisIns.$route.query.itemsPerPage + 1);
        if(tmpPage > 1) thisIns.currentx = tmpPage;
        thisIns.searchVar.department = '';
        if(thisIns.$route.query.startRow) thisIns.searchVar.startRow = parseInt(thisIns.$route.query.startRow);
        if(thisIns.$route.query.state) thisIns.searchVar.state = thisIns.$route.query.state;
        if(thisIns.$route.query.category1) thisIns.searchVar.category1 = thisIns.$route.query.category1;
        if(thisIns.$route.query.series) thisIns.searchVar.series = thisIns.$route.query.series;
        if(thisIns.$route.query.rank) thisIns.searchVar.rank = thisIns.$route.query.rank;
        if(thisIns.$route.query.writer) thisIns.searchVar.writer = thisIns.$route.query.writer;
        if(thisIns.$route.query.searchText) thisIns.searchVar.searchText = thisIns.$route.query.searchText;
        if(thisIns.$route.query.startDate) thisIns.searchVar.startDate = thisIns.$route.query.startDate;
        if(thisIns.$route.query.endDate) thisIns.searchVar.endDate = thisIns.$route.query.endDate;
        // if(thisIns.$route.query.department || thisIns.$route.query.department == '') thisIns.searchVar.department = thisIns.$route.query.department;
        // console.log('department: ', thisIns.activeUserInfo.department);
        thisIns.getNewsList(thisIns.searchVar);    
      })
      .catch(function (error) {
        var errorText = error.toString();
        if(errorText.includes('401')){
          alert('로그인 해주세요!')
          thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
        }
        thisIns.$vs.notify({
          title:'Error',
          text: error,
          color:'danger',
          iconPack: 'feather',
          icon:'icon-alert-circle'})
        thisIns.$vs.loading.close(); 
      });
  },
  mounted() {
    this.isMounted = true;
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/custom-style/vs-table-newslist.scss";
</style>
